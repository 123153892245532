"use client";

import ReactPlayerModal from '@octa-labs/react-player-modal';

export const VideoPlayer = () => {
    //video path
    let videosrc = "/assets/videos/risitas_bubratze.mp4";

    const customStyles = {
        background: '#000',
        padding: '20px',
        borderRadius: '10px',
        xColor: 'white'
    };

    return (
        <div className="mx-auto max-w-lg lg:mx-0 lg:flex lg:w-96 lg:max-w-none lg:flex-col lg:before:flex-1">
            <h2 className="mt-14 mb-4 font-display text-2xl/tight font-light text-white">Bubratze in Film und Fernsehen</h2>
            <ReactPlayerModal
                modalStyle={customStyles}
                buttonClassName="video-opener"
                buttonText="El Risitas über Bubratze"
                url={videosrc}
                controls={true}
                width="100%"
                height="100%"
            />
        </div>
    );
};