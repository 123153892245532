'use client';

import React, {useState} from 'react';

interface CollapsibleTextProps {
    text: string;
}

const CollapsibleText: React.FC<CollapsibleTextProps> = ({text}) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpansion = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className="mt-8 lg:mt-14 relative">
            <div className={`transition-all duration-500 ${isExpanded ? 'max-h-full' : 'max-h-96'}`}>
                <p className="mt-4 text-sm/6 text-gray-300">
                    Bubratze, die [fem.] gilt als die Königin unter den Cannabispflanzen. Sie ist das Ergebnis sorgfältiger Auswahl und Zucht. Ihre Genetik verleiht dieser Linie einen besonders buschigen Wuchs und eine ertragreiche Ernte. Ihre Blüten verströmen einen samtig-zitronigen Duft, der einen außergewöhnlich bekömmlichen und wohlschmeckenden Cannabiskonsum garantiert. Das Rauscherlebnis ist unglaublich belebend und intensiv.
                </p>

                {isExpanded && (
                    <div>
                        <p className="mt-4 text-sm/6 text-gray-300">
                            Der Lebenszyklus der Bubratze ist in vier Phasen unterteilt: Bubratzling, Bubratze, Blühbratze und Bubratz. In jeder Phase werden die Bubratzen sorgsam überwacht und gepflegt, um sicherzustellen, dass sie ihr volles Potenzial entfalten. Diese sorgfältige Pflege und das tiefe Verständnis für die Bubratze sowie ihre außergewöhnlich gute Genetik begründen ihren Ruf als wohl majestätischste Pflanze der Welt.
                        </p>
                        <p className="mt-4 text-sm/6 text-gray-300">
                            Drei Standorte, drei Grower, drei &ldquo;aktive&rdquo; Bubratzen in mittlerweile dritter Generation, eine DNA. Und die vierte Generation wartet schon darauf als Bubratzling gezogen zu werden, um die Qualität und die einzigartigen Eigenschaften dieser Linie auch weiterhin zu bewahren und fortzuführen. All dies kann hier live mitverfolgt werden.
                        </p>
                    </div>
                )}

                <button
                    onClick={toggleExpansion}
                    className="block mt-2 text-sm/6 text-emerald-600"
                >
                    {isExpanded ? 'Weniger' : 'Mehr...'}
                </button>
            </div>
        </div>
    );
};

export default CollapsibleText;
