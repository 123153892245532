"use client";

import dynamic from 'next/dynamic';

const LiveImage = dynamic(() => import('@/components/LiveImage'), { ssr: false });

interface ClientOnlyProps {
  src: string;
  alt: string;
  width: number;
  height: number;
  priority?: boolean;
}

const ClientOnly: React.FC<ClientOnlyProps> = (props) => {
    return (
        <div className="relative w-full aspect-[4/3]">
            <LiveImage {...props} />
        </div>
    );
};

export default ClientOnly;
