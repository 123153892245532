'use client';

import {useRef, useState, useEffect} from 'react';
import {ListPlayerContext, track, listInfo } from 'react-list-player';
import { ListPlayer } from '@/components/ListPlayer'

const infoList: listInfo = {
    type: 'playlist',
    name: '',
    creationDate: "",
    numTracks: 0,
    duration: "",
    imageSrc: "/assets/images/covers/e22dc9c6-8ff9-4c85-9118-ae49f8c588ff.jpg"

}

const trackList: track[] = [
    {
        title: [
            {
                type: 'text',
                content: 'Bubratze unsere',
                className: 'title'
            }
        ],
        artist: [
            {
                type: 'text',
                content: 'geheiligt werde dein Name',
                className: 'artist',
            },
        ],
        album: [
            {
                type: 'badge',
                content: 'E',
                className: 'explicit'
            }
        ],
        duration: "2:19",
        imageSrc: "/assets/images/covers/e22dc9c6-8ff9-4c85-9118-ae49f8c588ff.jpg",
        src: "/assets/songs/e22dc9c6-8ff9-4c85-9118-ae49f8c588ff.mp3"
    },
    {
        title: [
            {
                type: 'text',
                content: 'flavour 420-2',
                className: 'title'
            }
        ],
        artist: [
            {
                type: 'text',
                content: 'Ein Hauch von Eden',
                className: 'artist',
            }
        ],
        album: [
            {
                type: 'badge',
                content: 'E',
                className: 'explicit'
            }
        ],
        duration: "3:15",
        imageSrc: "/assets/images/covers/bc67673b-49fc-41c5-860d-78c5c6d4d182.jpg",
        src: "/assets/songs/bc67673b-49fc-41c5-860d-78c5c6d4d182.mp3"
    },
    {
        title: [
            {
                type: 'text',
                content: 'Bubratze unsere #2',
                className: 'title'
            }
        ],
        artist: [
            {
                type: 'text',
                content: 'Yeah, yeah, yeah',
                className: 'artist',
            }
        ],
        album: [
            {
                type: 'badge',
                content: 'E',
                className: 'explicit'
            }
        ],
        duration: "2:07",
        imageSrc: "/assets/images/covers/c46c7f16-8da5-4de0-8a19-24e1d9e0f881.jpg",
        src: "/assets/songs/c46c7f16-8da5-4de0-8a19-24e1d9e0f881.mp3"
    },
    {
        title: [
            {
                type: 'text',
                content: 'Bubratze',
                className: 'title'
            }
        ],
        artist: [
            {
                type: 'text',
                content: 'Von den Bergen bis zur Stadt',
                className: 'artist',
            }
        ],
        album: [
            {
                type: 'badge',
                content: 'E',
                className: 'explicit'
            }
        ],
        duration: "2:16",
        imageSrc: "/assets/images/covers/ea2b9a03-596e-4fdc-ba39-e54e9e3539d0.jpg",
        src: "/assets/songs/ea2b9a03-596e-4fdc-ba39-e54e9e3539d0.mp3"
    },
    {
        title: [
            {
                type: 'text',
                content: 'Bubratze #2',
                className: 'title'
            }
        ],
        artist: [
            {
                type: 'text',
                content: 'Von den Bergen bis zur Stadt',
                className: 'artist',
            }
        ],
        album: [
            {
                type: 'badge',
                content: 'E',
                className: 'explicit'
            }
        ],
        duration: "3:31",
        imageSrc: "/assets/images/covers/836cd585-50c2-4e65-ba84-597bc6675063.jpg",
        src: "/assets/songs/836cd585-50c2-4e65-ba84-597bc6675063.mp3"
    },
    {
        title: [
            {
                type: 'text',
                content: 'Winter is coming',
                className: 'title'
            }
        ],
        artist: [
            {
                type: 'text',
                content: 'das Eis bringt die Kraft',
                className: 'artist',
            }
        ],
        album: [
            {
                type: 'badge',
                content: 'E',
                className: 'explicit'
            }
        ],
        duration: "3:59",
        imageSrc: "/assets/images/covers/609c70c1-1d60-4770-8c5e-b00e7aaf8772.jpg",
        src: "/assets/songs/609c70c1-1d60-4770-8c5e-b00e7aaf8772.mp3"
    },
    {
        title: [
            {
                type: 'text',
                content: 'Bubratze 140bpm',
                className: 'title'
            }
        ],
        artist: [
            {
                type: 'text',
                content: 'Bubratze grow',
                className: 'artist',
            }
        ],
        album: [
            {
                type: 'badge',
                content: 'E',
                className: 'explicit'
            }
        ],
        duration: "2:37",
        imageSrc: "/assets/images/covers/ec618c49-52d4-4f97-a799-870fe6a1cb67.jpg",
        src: "/assets/songs/ec618c49-52d4-4f97-a799-870fe6a1cb67.mp3"
    },
]

export const MusicPlayer = () => {
    const [selectedTrack, setSelectedTrack] = useState(-1);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isMuted, setIsMuted] = useState(false);
    const [playerMode, setPlayerMode] = useState("tiny");
    const [forceSmallWidth, setForceSmallWidth] = useState(false);
    const [replaceHeader, setReplaceHeader] = useState(false);
    const [headLess, setHeadLess] = useState(false);

    const audioRef = useRef<HTMLAudioElement>(null);

    const audioSrcs = trackList.map(track => track.src);

    const handleOnPlay = (index: number, resume: boolean) => {
        if (index === selectedTrack && !resume) {
            audioRef.current?.load();
            audioRef.current?.play();
        } else {
            audioRef.current?.play();
        }
    }

    const handleOnPause = () => {
        audioRef.current?.pause();
    }

    useEffect(() => {
        if ('mediaSession' in navigator && selectedTrack !== -1) {
            const track = trackList[selectedTrack];
            if (typeof track.imageSrc === 'string') {
                navigator.mediaSession.metadata = new MediaMetadata({
                    title: track.title[0].content,
                    artist: track.artist[0].content,
                    album: '',
                    artwork: [
                        {src: track.imageSrc, sizes: '512x512', type: 'image/jpg'}
                    ]
                });
            }

            navigator.mediaSession.setActionHandler('play', () => { audioRef.current?.play(); });
            navigator.mediaSession.setActionHandler('pause', () => { audioRef.current?.pause(); });
            navigator.mediaSession.setActionHandler('previoustrack', () => {
                setSelectedTrack(selectedTrack > 0 ? selectedTrack - 1 : trackList.length - 1);
            });
            navigator.mediaSession.setActionHandler('nexttrack', () => {
                setSelectedTrack(selectedTrack < trackList.length - 1 ? selectedTrack + 1 : 0);
            });
        }
    }, [selectedTrack]);

    return (
        <ListPlayerContext.Provider
            value={{selectedTrack, setSelectedTrack, isPlaying, setIsPlaying, isMuted, setIsMuted}}>
            <div className="mt-8">
                <h2 className="mt-14 mb-4 font-display text-2xl/tight font-light text-white">
                    Bubratze für die Ohren
                </h2>
                <div className='listplayer-cont'
                     style={forceSmallWidth ? {paddingLeft: "20%", paddingRight: "20%"} : undefined}>
                    <ListPlayer
                        tracks={trackList}
                        listInfo={infoList}
                        playerMode={playerMode}
                        noControls={replaceHeader}
                        noHeader={headLess}
                        playCallback={handleOnPlay}
                        pauseCallback={handleOnPause}
                        loop={true}
                        kbdShortcuts
                    >
                    </ListPlayer>
                </div>
            </div>
            <audio ref={audioRef}
                   src={selectedTrack < audioSrcs.length ? audioSrcs[selectedTrack % audioSrcs.length] : undefined}
                   muted={isMuted}
                   onEnded={() => {
                       setSelectedTrack(selectedTrack < (audioSrcs.length-1) ? selectedTrack + 1 : 0)
                   }}
            />
        </ListPlayerContext.Provider>
    )
}