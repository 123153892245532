'use client'

import { useEffect, useId, useRef, useState } from 'react'
import clsx from 'clsx'
import { type TimelineSegment, animate, timeline } from 'motion'

type Star = [x: number, y: number, dim?: boolean, blur?: boolean]

const constellations: Array<Array<Star>> = [
  [    
    [120.6, 0], [120.6, 113.1]
  ],
  [ 
    [120.6, 113.1], [20.2, 171], [20.2, 264.1], [120.6, 339.4], [218.5, 281.5], [218.5, 168.4], [120.6, 111.7]
  ],
  [
    [119.3, 134.6], [199.4, 183.5]
  ],
  [
    [218.5, 281.5], [316.5, 340.7], [316.5, 452.6], [251.7, 491.4], 
  ],
  [
    [183.2, 490.1], [120.6, 451.2], [120.6, 339.4]
  ],
  [
    [414.5, 213.5], [414.5, 282.9] 
  ],
  [
    [316.5, 340.7], [414.5, 281.5], [514.9, 340.7], [512.6, 452.6], [414.5, 511.9], [316.5, 451.2]
  ],
  [
    [413.4, 304.3], [493.3, 353.2]
  ],
  [
    [493.3, 438.8], [413.4, 487.7]
  ],
  [
    [336.9, 351.1], [336.9, 440.9]
  ],
  [
    [512.6, 451.2], [610.6, 511.9], [708.5, 449.9], [806.5, 511.9], [904.4, 449.9], [1003.5, 511.2]
  ],
  [
    [120.6, 452.6], [7.3, 452.6],
  ],
  [
    [121.4, 452.6], [61.9, 551.8] 
  ]
]

// Helper function to check if a point is too close to any existing points
const isTooClose = (x: number, y: number, points: Star[], minDistance: number = 20) => {
  return points.some(([px, py]) => {
    const dx = px - x;
    const dy = py - y;
    return Math.sqrt(dx * dx + dy * dy) < minDistance;
  });
}

// Generate 15 random stars that do not collide with existing points
const generateRandomStars = (numStars: number, existingPoints: Star[], minDistance: number = 30) => {
  const randomStars: Star[] = [];
  const width = 881;
  const height = 700;

  while (randomStars.length < numStars) {
    const x = Math.random() * width;
    const y = Math.random() * height;
    if (!isTooClose(x, y, existingPoints.concat(randomStars), minDistance)) {
      randomStars.push([x, y]);
    }
  }

  return randomStars;
}

// Extract all existing points from constellations
const allExistingPoints: Star[] = ([] as Star[]).concat(...constellations);

function Star({
  blurId,
  point: [cx, cy, dim, blur],
}: {
  blurId: string
  point: Star
}) {
  let groupRef = useRef<React.ElementRef<'g'>>(null)
  let ref = useRef<React.ElementRef<'circle'>>(null)

  useEffect(() => {
    if (!groupRef.current || !ref.current) {
      return
    }

    let delay = Math.random() * 2

    let animations = [
      animate(groupRef.current, { opacity: 1 }, { duration: 4, delay }),
      animate(
        ref.current,
        {
          opacity: dim ? [0.2, 0.5] : [1, 0.6],
          scale: dim ? [1, 1.2] : [1.2, 1],
        },
        {
          delay,
          duration: Math.random() * 2 + 2,
          direction: 'alternate',
          repeat: Infinity,
        },
      ),
    ]

    return () => {
      for (let animation of animations) {
        animation.cancel()
      }
    }
  }, [dim])

  return (
    <g ref={groupRef} className="opacity-0">
      <circle
        ref={ref}
        cx={cx}
        cy={cy}
        r={1}
        style={{
          transformOrigin: `${cx / 16}rem ${cy / 16}rem`,
          opacity: dim ? 0.2 : 1,
          transform: `scale(${dim ? 1 : 1.2})`,
        }}
        filter={blur ? `url(#${blurId})` : undefined}
      />
    </g>
  )
}

function Constellation({
  points,
  blurId,
}: {
  points: Array<Star>
  blurId: string
}) {
  let ref = useRef<React.ElementRef<'path'>>(null)
  let uniquePoints = points.filter(
    (point, pointIndex) =>
      points.findIndex((p) => String(p) === String(point)) === pointIndex,
  )
  let isFilled = uniquePoints.length !== points.length

  useEffect(() => {
    if (!ref.current) {
      return
    }

    let sequence: Array<TimelineSegment> = [
      [
        ref.current,
        { strokeDashoffset: 0, visibility: 'visible' },
        { duration: 5, delay: Math.random() * 3 + 2 },
      ],
    ]

    if (isFilled) {
      sequence.push([
        ref.current,
        { fill: 'rgb(255 255 255 / 0.02)' },
        { duration: 1 },
      ])
    }

    let animation = timeline(sequence)

    return () => {
      animation.cancel()
    }
  }, [isFilled])

  return (
    <>
      <path
        ref={ref}
        stroke="white"
        strokeOpacity="0.2"
        strokeDasharray={1}
        strokeDashoffset={1}
        pathLength={1}
        fill="transparent"
        d={`M ${points.join('L')}`}
        className="invisible"
      />
      {uniquePoints.map((point, pointIndex) => (
        <Star key={pointIndex} point={point} blurId={blurId} />
      ))}
    </>
  )
}

export function StarField({ className }: { className?: string }) {
  let blurId = useId()
  const [stars, setStars] = useState<Star[]>([])

  useEffect(() => {
    setStars(generateRandomStars(15, allExistingPoints))
  }, [])

  return (
    <svg
      viewBox="-50 -50 881 700"
      fill="white"
      aria-hidden="true"
      className={clsx(
        'pointer-events-none absolute w-[40rem] origin-top-right rotate-[15deg] overflow-visible opacity-70',
        className,
      )}
    >
      <defs>
        <filter id={blurId}>
          <feGaussianBlur in="SourceGraphic" stdDeviation="13.5" />
        </filter>
      </defs>
      {constellations.map((points, constellationIndex) => (
        <Constellation
          key={constellationIndex}
          points={points}
          blurId={blurId}
        />
      ))}
      {stars.map((point, pointIndex) => (
        <Star key={pointIndex} point={point} blurId={blurId} />
      ))}
    </svg>
  )
}
