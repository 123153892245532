import(/* webpackMode: "eager" */ "/frontend/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/frontend/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/frontend/src/components/ClientOnly.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/frontend/src/components/CollapsibleText.tsx");
;
import(/* webpackMode: "eager" */ "/frontend/src/components/mdx.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MusicPlayer"] */ "/frontend/src/components/MusicPlayer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["StarField"] */ "/frontend/src/components/StarField.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["VideoPlayer"] */ "/frontend/src/components/VideoPlayer.tsx");
